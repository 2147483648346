import {createApi, fakeBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import api from "../../api";

export const reduxApi = createApi({
    reducerPath: 'reduxApi',
    baseQuery: fakeBaseQuery(),
    endpoints: (build) => ({
        getBulkSoftDeleteRecords: build.query({
            async queryFn(_, {getState, dispatch}) {
                try {
                    const records = await api.getBulkSoftDeleteRecords();
                    return { data: records.data };
                } catch (error) {
                    return { error }
                }
            },
        }),
        bulkDeleteLocations: build.mutation({
            async queryFn(rows) {
                try {
                    const result = await api.bulkSoftDeleteRecords();
                    return { data: result };
                } catch (error) {
                    return { error };
                }
            }
        }),
    }),
});

export const {
    useGetBulkSoftDeleteRecordsQuery, useBulkDeleteLocationsMutation,
} = reduxApi;
