import React from 'react';
import {configureStore} from "@reduxjs/toolkit";
import {Provider} from 'react-redux';
import {ThemeProvider} from '@mui/material';
import {SnackbarProvider} from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";

import api from './api';
import rootReducer from './redux';
import theme from './materialUI/theme';
import AuthListener from "./components/authListener";
import Login from "./components/login";
import Home from './components/home';
import { reduxApi } from './redux/api';
import { snackbarErrorLogger } from "./utils/snackbarErrorLogger";

/**
 * Create a Redux store and configure extra argument for Thunk middleware
 */
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { api }
            },
            serializableCheck: false,
        }).concat([reduxApi.middleware, snackbarErrorLogger])
});

const App = () => {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Provider store={store}>
                        <div className="text-center">
                            <AuthListener unAuthed={<Login/>}>
                                <Home/>
                            </AuthListener>
                        </div>
                    </Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </SnackbarProvider>
    );
};

export default App;
