import { combineReducers } from 'redux'
import { useSelector, shallowEqual } from 'react-redux';

import attributes from './attributes';
import imports from './imports';
import login from './login';
import { reduxApi } from './api';

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    attributes,
    imports,
    login,
    [reduxApi.reducerPath]: reduxApi.reducer
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = (selector) => {
    return useSelector(selector, shallowEqual);
};


export default RootReducer;
