import { createSlice } from '@reduxjs/toolkit';
import attributeIds from "@devsontap/nica-api/core/utils/attributeIds";

const INITIAL_STATE = {
    loading: false,
    importsMap: {},
    unsubscribe: null,
    hits: null,
    misses: null
};

const importsSlice = createSlice({
    name: 'imports',
    initialState: INITIAL_STATE,
    reducers: {
        importsSuccess(state, action) {
            state.loading = false;
            state.importsMap = action.payload.reduce((ret, importRecord) => {
                ret[importRecord.id] = importRecord;
                return ret;
            }, state.importsMap);
        },
        importSuccess(state, action) {
            state.loading = false;
            state.importsMap[action.payload.id] = action.payload;
        },
        loading(state, action) {
            state.loading = action.payload;
        },
        importUnsubscribe(state, action) {
            state.unsubscribe = action.payload;
        },
        hitsSuccess(state, action) {
            state.hits = action.payload;
        },
        missesSuccess(state, action) {
            state.misses = action.payload;
        },
        clear(state, action) {
            state.hits = null;
            state.misses = null;
            state.unsubscribe = null;
        }
    }
});

export const { importsSuccess, importSuccess, loading, importUnsubscribe, hitsSuccess, missesSuccess, clear } = importsSlice.actions;

export default importsSlice.reducer

// CUSTOM THUNK ACTIONS

export const fetchImports = (importType, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.locationImports.getByType(importType)
            .then(imports => {
                console.log("imports", imports);
                dispatch(importsSuccess(imports));
            })
            .catch(error => {
                dispatch(loading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const saveImport = (importRecord, file, navigate, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.locationImports.save(importRecord)
            .then(async newImport => {
                console.log("import", newImport);
                dispatch(importSuccess(newImport));

                if (file) {
                    await api.uploadFile(file, `imports/${newImport.id}`)
                }

                if (navigate) {
                    navigate(newImport.id)
                }
            })
            .catch(error => {
                dispatch(loading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const listenToImport = (importId) => (
    (dispatch, getState, { api }) => {
        const unsubscribe = api.locationImports.listen(importId, importRecord => {
            console.log("listen update", importRecord);
            dispatch(importSuccess(importRecord));
        });
        dispatch(importUnsubscribe(unsubscribe));
    }
);

export const fetchSpreadsheetData = (locationImportId, sheetName, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        return api.locationImports.getSpreadsheetData(locationImportId, sheetName)
            .then(data => {
                console.log("sheet data", data);
                // Ugh, this sucks, but whatever
                // TODO: Make enum for these sheet names
                if (sheetName === "Hits") {
                    dispatch(hitsSuccess(data));
                } else {
                    dispatch(missesSuccess(data));
                }
            })
            .catch(error => {
                dispatch(loading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const updateLocationHit = (locationImportId, locationId, name, address, city, state, zip, hits, rowIndex, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch(loading(true));
        console.log("updateLocationHit", locationId);

        const importRecord = getState().imports.importsMap[locationImportId];

        return api.locations.get(locationId)
            .then(async location => {
                // TODO: Update Medications
                location.name = name;
                location.address.street1 = address;
                location.address.city = city;
                location.address.stateCode = state;
                location.address.zip = zip;

                console.log("importRecord", importRecord);

                await _updateLocationMedications(location, attributeIds.medicationsAvailable, importRecord.medicationIds);

                await api.locations.save(location);

                const newHits = [...hits];
                newHits.splice(rowIndex, 1);

                dispatch(hitsSuccess(newHits));

                await api.locationImports.updateSpreadsheetData(locationImportId, "Hits", newHits);
                await api.locationImports.appendSpreadsheetData(locationImportId, "Imported Hits", [[locationId, name, address, city, state, zip]]);

                const locationImport = getState().imports.importsMap[locationImportId];
                const updatedImport = await api.locationImports.save({
                    ...locationImport,
                    numHits: locationImport.numHits - 1,
                    numImportedHits: locationImport.numImportedHits + 1
                });
                dispatch(importSuccess(updatedImport));
            })
            .catch(error => {
                dispatch(loading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const importLocationMiss = (locationImportId, name, line1, city, stateCode, zip, phoneNumber, misses, rowIndex, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch(loading(true));

        let location = {
            name,
            address: {
                line1,
                city,
                stateCode,
                zip
            },
            latitude: misses[rowIndex][16] || misses[rowIndex][5],
            longitude: misses[rowIndex][17] || misses[rowIndex][6],
            phoneNumber,
            attributes: {},
            bulkUploadId: locationImportId,
            active: true
        };

        const importRecord = getState().imports.importsMap[locationImportId];

        _updateLocationMedications(location, attributeIds.medicationsAvailable, importRecord.medicationIds);

        location = await api.locations.save(location);

        // update spreadsheets
        const newMisses = [...misses];

        console.log("importMiss rowIndex", rowIndex);

        newMisses.splice(rowIndex, 1);

        dispatch(missesSuccess(newMisses));

        await api.locationImports.updateSpreadsheetData(locationImportId, "Misses", newMisses);
        await api.locationImports.appendSpreadsheetData(locationImportId, "Imported Misses", [[location.id, name, line1, city, stateCode, zip, phoneNumber]]);

        const locationImport = getState().imports.importsMap[locationImportId];
        const updatedImport = await api.locationImports.save({
            ...locationImport,
            numMisses: locationImport.numMisses - 1,
            numImportedMisses: locationImport.numImportedMisses + 1
        });
        dispatch(importSuccess(updatedImport));

        // return api.geocodeAddress(encodeURI(`${location.address.line1} ${location.address.city}, ${location.address.stateCode} ${location.address.zip}`))
        //     .then(async geocodeResults => {
        //         if (geocodeResults) {
        //             const {lat, lng} = geocodeResults;
        //             location.latitude = lat;
        //             location.longitude = lng;
        //             _updateLocationMedications(location, attributeIds.medicationsAvailable, importRecord.medicationIds)
        //
        //             location = await api.locations.save(location);
        //
        //             // update spreadsheets
        //             const newMisses = [...misses];
        //
        //             console.log("importMiss rowIndex", rowIndex);
        //
        //             newMisses.splice(rowIndex, 1);
        //
        //             dispatch(missesSuccess(newMisses));
        //
        //             await api.locationImports.updateSpreadsheetData(locationImportId, "Misses", newMisses);
        //             await api.locationImports.appendSpreadsheetData(locationImportId, "Imported Misses", [[location.id, name, line1, city, stateCode, zip, phoneNumber]]);
        //
        //             const locationImport = getState().imports.importsMap[locationImportId];
        //             const updatedImport = await api.locationImports.save({
        //                 ...locationImport,
        //                 numMisses: locationImport.numMisses - 1,
        //                 numImportedMisses: locationImport.numImportedMisses + 1
        //             });
        //             dispatch(importSuccess(updatedImport));
        //         } else {
        //             enqueueSnackbar("Could not geocode address", { variant: "error" });
        //         }
        //     })
        //     .catch(error => {
        //         dispatch(loading(false));
        //         console.error(error);
        //         enqueueSnackbar(error.message, {variant: 'error'});
        //     });
    }
);

export const rejectLocationHit = (locationImportId, hits, rowIndex, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch(loading(true));

        // update spreadsheets
        const newHits = [...hits];
        const rejected = newHits.splice(rowIndex + 1, 1);
        const misses = getState().imports.misses;

        dispatch(hitsSuccess(newHits));

        const missRow = await api.locationImports.rejectLocationHit(locationImportId, newHits, rejected[0]);

        dispatch(missesSuccess([...misses, missRow]));
        dispatch(loading(false));

        const locationImport = getState().imports.importsMap[locationImportId];
        const updatedImport = await api.locationImports.save({
            ...locationImport,
            numHits: locationImport.numHits - 1,
            numMisses: locationImport.numMisses + 1
        });
        dispatch(importSuccess(updatedImport));
    }
);

export const rejectLocationMiss = (locationImportId, misses, rowIndex, enqueueSnackbar) => (
    async (dispatch, getState, { api }) => {
        dispatch(loading(true));

        // update spreadsheets
        const newMisses = [...misses];
        const rejected = newMisses.splice(rowIndex + 1, 1);

        dispatch(missesSuccess(newMisses));

        await api.locationImports.updateSpreadsheetData(locationImportId, "Misses", newMisses);
        await api.locationImports.appendSpreadsheetData(locationImportId, "Rejected", rejected);

        dispatch(loading(false));

        const locationImport = getState().imports.importsMap[locationImportId];
        const updatedImport = await api.locationImports.save({
            ...locationImport,
            numMisses: locationImport.numMisses - 1,
            numRejected: locationImport.numRejected + 1
        });
        dispatch(importSuccess(updatedImport));
    }
);

function _updateLocationMedications(location, medicationsAvailableKey, medications) {
    medications.forEach((medication) => {
        const [groupId, optionId] = medication.split("|");
        _updateAttributeChoice(location, medicationsAvailableKey, optionId);
        _updateAttributeChoice(location, groupId, optionId);
    });
}

function _updateAttributeChoice(location, attributeId, optionId) {
    if (!location.attributes[attributeId]) {
        location.attributes[attributeId] = {
            choice: [optionId],
            required: false
        };
    } else if (!location.attributes[attributeId].choice.includes(optionId)) {
        location.attributes[attributeId].choice.push(optionId);
    }
}