import {
    ATTRIBUTES_LOADING,
    ATTRIBUTES_SUCCESS,
    ATTRIBUTES_ERROR
} from "./types";

/**
 * Loads all the attributes from RTDB
 */
export const loadAttributes = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: ATTRIBUTES_LOADING });
        return api.getAttributes()
            .then(attributes => {
                return dispatch({ type: ATTRIBUTES_SUCCESS, payload: attributes });
            })
            .catch(error => {
                console.error(error);
                dispatch({ type: ATTRIBUTES_ERROR, error });
            })
    }
);