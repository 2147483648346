import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import {signInWithGoogle} from "../../../redux/login";
import {useLoginSelector} from "../../../redux/login/selectors";

const LoginForm = () => {
    const {loggingIn} = useLoginSelector();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div>
            <div className="font-lato text-blue text-xl mb-5">
                Sign In
            </div>
            <div>
                {loggingIn ?
                    <div className="flex justify-center">
                        <CircularProgress size={35} />
                    </div> :
                    <>
                        <div className="mt-8 mb-4">
                            <div>
                                <Button variant="contained" color="primary" fullWidth onClick={() => dispatch(signInWithGoogle("infusioncenter.org", enqueueSnackbar))}>
                                    Login with infusioncenter.org
                                </Button>
                            </div>
                            <div className="mt-5">
                                <Button variant="contained" color="primary" fullWidth onClick={() => dispatch(signInWithGoogle("devsontap.com", enqueueSnackbar))}>
                                    Login with devsontap.com
                                </Button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default LoginForm;
