import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Nav from '../nav';
import ImportsList from "../imports";
import BulkSoftDelete from "../bulkSoftDelete";

const Home = () => {
    return (
        <Router>
            <div>
                <div className="fixed t-0 l-0">
                    <Nav />
                </div>
                <div className="ml-[300px] relative">
                    <AppBar position="sticky" color="primary">
                        <Toolbar>
                            <Routes>
                                <Route path="/nica" element="NICA Location Imports" />
                                <Route path="/abc" element="ABC Imports" />
                                <Route path="/delete" element="Bulk Soft Delete" />
                            </Routes>
                        </Toolbar>
                    </AppBar>
                    <div className="flex-1">
                        <Routes>
                            <Route path="/delete" element={<BulkSoftDelete />} />
                            <Route path="/:importType" element={<ImportsList />}>
                                <Route path=":importId" />
                            </Route>
                            <Route path="*" element={() => <Navigate to="/nica" />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    )
};

export default Home;
