import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LeftArrow from "@mui/icons-material/ArrowBack";
import OpenIcon from "@mui/icons-material/Launch";

import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import DropZone from "../../_common/dropZone";
import { listenToImport, saveImport, clear } from "../../../redux/imports";
import { importSelector, importUnsubscribeSelector } from "../../../redux/imports/selectors";
import ImportReconciler from "./reconcile";
import MedicationSelect from "../../_common/medicationSelect";

const ImportDetails = () => {
    const [uploading, setUploading] = useState(false);
    const [selectedMedications, setSelectedMedications] = useState(null);
    const [medicationsDone, setMedicationsDone] = useState(false);
    const { importId, importType } = useParams();
    const importRecord = useSelector(importSelector(importId));
    const unsubscribe = useSelector(importUnsubscribeSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (importId && importId !== "add" && unsubscribe === null) {
            dispatch(listenToImport(importId));
        }
    }, [importId, dispatch, unsubscribe]);

    const activeStep = useMemo(() => {
        if (!importRecord) {
            if (importId === "add" || !importId) {
                if (!medicationsDone) {
                    return 0;
                }
                return 1;
            }
        } else if (!importRecord.sheetId) {
            return 1;
        }
        return 2;

        // if (!importRecord || (importRecord.numHits === 0 && importRecord.numMisses === 0)) {
        //     return 0;
        // }
        // if (importRecord.completionDate) {
        //     return 2;
        // }
        // return 1;
    }, [importRecord, importId, medicationsDone]);

    const uploadImportFile = (file) => {
        if (file) {
            console.log("uploading file", file);
            const record = { importType: importType.toUpperCase(), medicationIds: selectedMedications.map(medication => `${medication.groupKey}|${medication.key}`) };
            dispatch(saveImport(record, file, navigate, enqueueSnackbar));
            setUploading(true);
        }
    };

    const openSpreadsheet = () => window.open(`https://docs.google.com/spreadsheets/d/${importRecord.sheetId}/edit`);

    const onClear = () => {
        dispatch(clear());
    };

    const onBack = () => {
        navigate(`/${importType}`);
        onClear();
    };

    return (
        <div className="h-full flex flex-col">
            <AppBar position="sticky" color="primary">
                <Toolbar>
                    <div className="flex items-center">
                        <div>
                            <IconButton onClick={onBack}>
                                <LeftArrow htmlColor="#ffffff" />
                            </IconButton>
                        </div>
                        <div>
                            Bulk Import
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="flex-1 p-8">
                <Stepper activeStep={activeStep}>
                    <Step>
                        <StepLabel>
                            Choose Medications
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            Upload File
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            Reconcile Data
                        </StepLabel>
                    </Step>
                </Stepper>
                {activeStep === 0 &&
                    <div className="mt-8 flex flex-col items-center">
                        <div>
                            <div className="font-bold mb-2 text-lg">
                                Choose Medications
                            </div>
                            <div className="w-[400px]">
                                <MedicationSelect selectedMedications={selectedMedications} setSelectedMedications={setSelectedMedications} />
                            </div>
                            <div className="mt-12">
                                <Button variant="contained" color="primary" onClick={() => setMedicationsDone(true)} fullWidth>
                                    Next
                                </Button>
                            </div>
                        </div>

                    </div>
                }
                {activeStep === 1 &&
                    <div className="mt-8">
                        <DropZone
                            loading={uploading}
                            onDrop={(files) => uploadImportFile(files[0])}
                            accept={[".xls", ".xlsx"]} />
                    </div>
                }
                {activeStep === 2 &&
                    <div>
                        <div className="flex justify-end mb-6">
                            <Button variant="contained" color="primary" onClick={openSpreadsheet}>
                                <OpenIcon className="mr-1" />View Spreadsheet
                            </Button>
                        </div>
                        <ImportReconciler />
                    </div>
                }
            </div>
        </div>
    )
};

export default ImportDetails;
