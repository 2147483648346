import React, { useState } from 'react';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import cx from "classnames";
import TextField from "@mui/material/TextField";

import NICADialog from "../../../_common/dialog";
import { SOURCE_TYPE_IMPORT, SOURCE_TYPE_OTHER } from "./";

const AcceptMissDialog = ({ acceptMiss, onClose, onAcceptMiss }) => {
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zip, setZip] = useState(null);
    const [phone, setPhone] = useState(null);

    const closeAcceptMissDialog = () => {
        setName(null);
        setAddress(null);
        setCity(null);
        setState(null);
        setZip(null);
        setPhone(null);
        onClose();
    };

    const selectAllImport = () => {
        setName(acceptMiss[0]);
        setAddress(acceptMiss[1]);
        setCity(acceptMiss[2]);
        setState(acceptMiss[3]);
        setZip(acceptMiss[4]);
    };

    const selectAllNICA = () => {
        setName(acceptMiss[8]);
        setAddress(acceptMiss[9]);
        setCity(acceptMiss[10]);
        setState(acceptMiss[11]);
        setZip(acceptMiss[12]);
        setPhone(acceptMiss[15]);
    };

    const selectAllOther = () => {
        setName('');
        setAddress('');
        setCity('');
        setState('');
        setZip('');
        setPhone('');
    };

    return (
        <NICADialog
            onClose={closeAcceptMissDialog}
            open={acceptMiss[0] !== undefined}
            title="Reconcile Miss"
            maxWidth="lg">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                                <div className="flex">
                                    <div className="flex items-center font-bold">
                                        {SOURCE_TYPE_IMPORT}
                                    </div>
                                    <div className="ml-6">
                                        <Button variant="text" onClick={selectAllImport}>
                                            Select All
                                        </Button>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className="flex">
                                    <div className="flex items-center font-bold">
                                        Google Places
                                    </div>
                                    <div className="ml-6">
                                        <Button variant="text" onClick={selectAllNICA}>
                                            Select All
                                        </Button>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className="flex">
                                    <div className="flex items-center font-bold">
                                        {SOURCE_TYPE_OTHER}
                                    </div>
                                    <div className="ml-6">
                                        <Button variant="text" onClick={selectAllOther}>
                                            Select All
                                        </Button>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    Name
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": name === acceptMiss[0]
                                })} onClick={() => setName(acceptMiss[0])}>
                                    {acceptMiss[0]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": name === acceptMiss[8]
                                })} onClick={() => setName(acceptMiss[8])}>
                                    {acceptMiss[8]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": name !== acceptMiss[0] && name !== acceptMiss[8]
                                })}>
                                    <TextField
                                        name="name"
                                        placeholder="Bob's Infusion Palace"
                                        label="Location Name"
                                        onChange={e => setName(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    Address
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": address === acceptMiss[1]
                                })} onClick={() => setAddress(acceptMiss[1])}>
                                    {acceptMiss[1]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": address === acceptMiss[9]
                                })} onClick={() => setAddress(acceptMiss[9])}>
                                    {acceptMiss[9]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": address !== acceptMiss[1] && address !== acceptMiss[9]
                                })}>
                                    <TextField
                                        name="address"
                                        placeholder="123 Street Place Drive"
                                        label="Location Address"
                                        onChange={e => setAddress(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    City
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": city === acceptMiss[2]
                                })} onClick={() => setCity(acceptMiss[2])}>
                                    {acceptMiss[2]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": city === acceptMiss[10]
                                })} onClick={() => setCity(acceptMiss[10])}>
                                    {acceptMiss[10]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": city !== acceptMiss[2] && city !== acceptMiss[10]
                                })}>
                                    <TextField
                                        name="city"
                                        placeholder="Austin"
                                        label="Location City"
                                        onChange={e => setCity(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    State
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": state === acceptMiss[3]
                                })} onClick={() => setState(acceptMiss[3])}>
                                    {acceptMiss[3]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": state === acceptMiss[11]
                                })} onClick={() => setState(acceptMiss[11])}>
                                    {acceptMiss[11]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": state !== acceptMiss[3] && state !== acceptMiss[11]
                                })}>
                                    <TextField
                                        name="state"
                                        placeholder="TX"
                                        label="State"
                                        onChange={e => setState(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    Zip
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": zip === acceptMiss[4]
                                })} onClick={() => setZip(acceptMiss[4])}>
                                    {acceptMiss[4]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": zip === acceptMiss[12]
                                })} onClick={() => setZip(acceptMiss[12])}>
                                    {acceptMiss[12]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": zip !== acceptMiss[4] && zip !== acceptMiss[12]
                                })}>
                                    <TextField
                                        name="zip"
                                        placeholder="12345"
                                        label="Location Zip"
                                        onChange={e => setZip(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    Phone
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4")}>
                                    --
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": phone === acceptMiss[15]
                                })} onClick={() => setPhone(acceptMiss[15])}>
                                    {acceptMiss[15]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": phone !== acceptMiss[15]
                                })}>
                                    <TextField
                                        name="phoneNumber"
                                        placeholder="512-555-5555"
                                        label="Location Phone"
                                        onChange={e => setPhone(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-end mt-6">
                <div className="mr-4">
                    <Button variant="text" onClick={closeAcceptMissDialog} color="default">
                        Cancel
                    </Button>
                </div>
                <Button variant="text" 
                        onClick={() => onAcceptMiss(name, address, city, state, zip, phone)}
                        disabled={!name || !address || !city || !state || !zip || !phone}>
                    Save
                </Button>
            </div>
        </NICADialog>
    )
};

export default AcceptMissDialog;
