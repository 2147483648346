import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSnackbar } from "notistack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableContainer from "@mui/material/TableContainer";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";
import Launch from "@mui/icons-material/Launch";
import CircularProgress from "@mui/material/CircularProgress";

import {
    fetchSpreadsheetData,
    updateLocationHit,
    importLocationMiss,
    rejectLocationMiss, rejectLocationHit
} from "../../../../redux/imports";
import { importHitsSelector, importMissesSelector, importSelector } from "../../../../redux/imports/selectors";
import NICADialog from "../../../_common/dialog";
import AcceptMissDialog from "./acceptMissDialog";
import AcceptHitDialog from "./acceptHitDialog";

export const SOURCE_TYPE_IMPORT = "Import";
export const SOURCE_TYPE_NICA = "NICA";
export const SOURCE_TYPE_OTHER = "Other";

const ImportReconciler = () => {
    const [tab, setTab] = useState(0);
    const [acceptHitRow, setAcceptHitRow] = useState(null);
    const [rejectHitRow, setRejectHitRow] = useState(null);
    const [rejectMissRow, setRejectMissRow] = useState(null);
    const [acceptMissRow, setAcceptMissRow] = useState(null);
    const dispatch = useDispatch();
    const { importId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const hits = useSelector(importHitsSelector);
    const misses = useSelector(importMissesSelector);
    const importRecord = useSelector(importSelector(importId));

    useEffect(() => {
        if (!importRecord?.completionDate && (importRecord?.numHits > 0 || importRecord?.numMisses > 0)) {
            // Load both data sets as soon as the page loads
            dispatch(fetchSpreadsheetData(importId, "Hits", enqueueSnackbar));
            dispatch(fetchSpreadsheetData(importId, "Misses", enqueueSnackbar));
        }
    }, [importId, dispatch, enqueueSnackbar, importRecord]);

    const acceptHit = useMemo(() => {
        if (acceptHitRow !== null) {
            return hits[acceptHitRow];
        }
        return {};
    }, [acceptHitRow, hits]);

    const acceptMiss = useMemo(() => {
        if (acceptMissRow !== null) {
            return misses[acceptMissRow];
        }
        return {};
    }, [acceptMissRow, misses]);

    const closeAcceptHitDialog = () => {
        setAcceptHitRow(null);
    };

    const onAcceptHit = async (name, line1, city, state, zip) => {
        dispatch(updateLocationHit(importId, acceptHit[0], name, line1, city, state, zip, hits, acceptHitRow, enqueueSnackbar));
        closeAcceptHitDialog();
    };

    const onAcceptMiss = async (name, line1, city, state, zip, phone) => {
        dispatch(importLocationMiss(importId, name, line1, city, state, zip, phone, misses, acceptMissRow, enqueueSnackbar));
        setAcceptMissRow(null);
    };

    const onRejectMiss = async () => {
        dispatch(rejectLocationMiss(importId, misses, rejectMissRow, enqueueSnackbar));
        setRejectMissRow(null);
    };

    const onRejectHit = async () => {
        dispatch(rejectLocationHit(importId, hits, rejectHitRow, enqueueSnackbar));
        setRejectHitRow(null);
    };

    return (
        <div>
            <Paper>
                <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                    <Tab value={0} label={`Hits (${hits ? hits.length - 1 : 0})`} />
                    <Tab value={1} label={`Misses (${misses ? misses.length - 1 : 0})`} />
                </Tabs>
                {tab === 0 &&
                    <div>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="text-bold">
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            Address
                                        </TableCell>
                                        <TableCell>
                                            City
                                        </TableCell>
                                        <TableCell>
                                            State
                                        </TableCell>
                                        <TableCell>
                                            Zip
                                        </TableCell>
                                        <TableCell>
                                            Name Match?
                                        </TableCell>
                                        <TableCell>
                                            Address Match?
                                        </TableCell>
                                        <TableCell>
                                            Fuzzy Match?
                                        </TableCell>
                                        <TableCell>
                                            Action
                                        </TableCell>
                                        {/*{hits[0].map(header => (*/}
                                        {/*    <TableCell key={`header-${header}`} align="left">*/}
                                        {/*        {header}*/}
                                        {/*    </TableCell>*/}
                                        {/*))}*/}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!hits &&
                                        <TableRow>
                                            <TableCell colSpan={9} align="center">
                                                <CircularProgress size={45} />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {hits && !hits.length &&
                                        <TableRow>
                                            <TableCell colSpan={9} align="center">
                                                No records to show.
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {hits?.length &&
                                        hits.slice(1).map((row, index) => (
                                            <TableRow key={`${hits}-row-${index}`}>
                                                <TableCell align="left">
                                                    <div>
                                                        {row[1]}
                                                    </div>
                                                    <div>
                                                        {row[2]}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div>
                                                        {row[3]}
                                                    </div>
                                                    <div>
                                                        {row[4]}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div>
                                                        {row[5]}
                                                    </div>
                                                    <div>
                                                        {row[6]}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div>
                                                        {row[7]}
                                                    </div>
                                                    <div>
                                                        {row[8]}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div>
                                                        {row[9]}
                                                    </div>
                                                    <div>
                                                        {row[10]}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[11]}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[12]}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[13]}
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex">
                                                        <div>
                                                            <Button variant="contained" color="default" onClick={() => setAcceptHitRow(index + 1)}>
                                                                Accept
                                                            </Button>
                                                        </div>
                                                        <div className="ml-3">
                                                            <Button variant="contained" color="default" onClick={() => setRejectHitRow(index)}>
                                                                Reject
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                {/*{row.map(value => (*/}
                                                {/*    <TableCell*/}
                                                {/*        key={`${tableName}-row-${index}-cell-${configValue.key}`}*/}
                                                {/*        align="left">*/}
                                                {/*        {configValue.format ? configValue.format(get(row, configValue.key), row) : get(row, configValue.key)}*/}
                                                {/*    </TableCell>*/}
                                                {/*))}*/}
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
                {tab === 1 &&
                    <div>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="text-bold">
                                            Name
                                        </TableCell>
                                        <TableCell>
                                            Address
                                        </TableCell>
                                        <TableCell>
                                            City
                                        </TableCell>
                                        <TableCell>
                                            State
                                        </TableCell>
                                        <TableCell>
                                            Zip
                                        </TableCell>
                                        <TableCell>
                                            Lat
                                        </TableCell>
                                        <TableCell>
                                            Lng
                                        </TableCell>
                                        <TableCell>
                                            GP Types
                                        </TableCell>
                                        <TableCell>
                                            GP Phone
                                        </TableCell>
                                        <TableCell>
                                            GP Link
                                        </TableCell>
                                        <TableCell>
                                            Action
                                        </TableCell>
                                        {/*{hits[0].map(header => (*/}
                                        {/*    <TableCell key={`header-${header}`} align="left">*/}
                                        {/*        {header}*/}
                                        {/*    </TableCell>*/}
                                        {/*))}*/}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!misses &&
                                        <TableRow>
                                            <TableCell colSpan={11} align="center">
                                                <CircularProgress size={45} />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {misses && !misses.length &&
                                        <TableRow>
                                            <TableCell colSpan={11} align="center">
                                                No records to show.
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {misses?.length &&
                                        misses.slice(1).map((row, index) => (
                                            <TableRow key={`${hits}-row-${index}`}>
                                                <TableCell align="left">
                                                    <div>
                                                        {row[0]}
                                                    </div>
                                                    <div>
                                                        {row[8]}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        {row[1]}
                                                    </div>
                                                    <div>
                                                        {row[9]}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        {row[2]}
                                                    </div>
                                                    <div>
                                                        {row[10]}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        {row[3]}
                                                    </div>
                                                    <div>
                                                        {row[11]}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        {row[4]}
                                                    </div>
                                                    <div>
                                                        {row[12]}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[5]}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[6]}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[13] ? row[13].split(", ").map(type => <div key={`${index}-${type}`}>{type}<br/></div>) : ""}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[15]}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row[14] &&
                                                        <a href={row[14]} target="_blank" rel="noopener noreferrer">
                                                            <Launch /> Open
                                                        </a>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex">
                                                        <div>
                                                            <Button variant="contained" color="default" onClick={() => setAcceptMissRow(index + 1)}>
                                                                Accept
                                                            </Button>
                                                        </div>
                                                        <div className="ml-3">
                                                            <Button variant="contained" color="default" onClick={() => setRejectMissRow(index)}>
                                                                Reject
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                {/*{row.map(value => (*/}
                                                {/*    <TableCell*/}
                                                {/*        key={`${tableName}-row-${index}-cell-${configValue.key}`}*/}
                                                {/*        align="left">*/}
                                                {/*        {configValue.format ? configValue.format(get(row, configValue.key), row) : get(row, configValue.key)}*/}
                                                {/*    </TableCell>*/}
                                                {/*))}*/}
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            </Paper>
            <NICADialog
                onClose={() => setRejectHitRow(null)}
                open={rejectHitRow !== null}
                title="Confirm Reject Hit"
                actions={[
                    { label: "Cancel", onClick: () => setRejectHitRow(null) },
                    { label: "Reject", onClick: onRejectHit }
                ]}>
                Are you sure you want to reject the hit for {rejectHitRow !== null && hits[rejectHitRow + 1][1]}?
            </NICADialog>
            <NICADialog
                onClose={() => setRejectMissRow(null)}
                open={rejectMissRow !== null}
                title="Confirm Reject Miss"
                actions={[
                    { label: "Cancel", onClick: () => setRejectMissRow(null) },
                    { label: "Reject", onClick: onRejectMiss }
                ]}>
                Are you sure you want to reject the miss for {rejectMissRow !== null && misses[rejectMissRow + 1][0]}?
            </NICADialog>
            <AcceptMissDialog
                acceptMiss={acceptMiss}
                onClose={() => setAcceptMissRow(null)}
                onAcceptMiss={onAcceptMiss} />
            <AcceptHitDialog
                acceptHit={acceptHit}
                onClose={() => setAcceptHitRow(null)}
                onAcceptHit={onAcceptHit} />
        </div>
    );
};

export default ImportReconciler;
