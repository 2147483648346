import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import NicaApi from '@devsontap/nica-api';

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

export function isLocal() {
    return document.domain === "localhost";
}

// firebase.functions().useEmulator("localhost", 5003);

const api = new NicaApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage);

api.loginWithGoogle = (domain) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
        hd: domain
    });
    return firebase.auth().signInWithPopup(provider);
};

api.logout = () => {
    return firebase.auth().signOut()
        .then(() => {
            console.log("signed out");
        });
};

api.getBulkSoftDeleteRecords = () => {
    return firebase.functions().httpsCallable('locationImports-getBulkSoftDeleteLocations')({});
}

api.bulkSoftDeleteRecords = () => {
    return firebase.functions().httpsCallable('locationImports-bulkSoftDeleteLocations')({});
}

export default api;
