import React, {useState} from 'react'
import { useDropzone } from 'react-dropzone'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import NICADialog from '../dialog';

/**
 * Renders a drag-and-drop area that users can drag files into
 *
 * TODO: Make button work
 *
 * @param accept        An array of file types to accept
 * @param onDrop        The callback function triggered when files are dropped or picked from the file input
 * @param loading       If true the widget renders a CircularProgress bar
 */
const DropZone = ({accept, onDrop, loading, outline}) => {
    const [showNotSupportedDialog, setShowNotSupportedDialog] = useState(false);

    // const onDropCallback = useCallback(acceptedFiles => {
    //     onDrop(acceptedFiles);
    // }, []);  // eslint-disable-line react-hooks/exhaustive-deps



    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept,
        onDropAccepted: onDrop,
        onDropRejected: () => { setShowNotSupportedDialog(true); }
    });

    return (
        <div {...getRootProps()} className={`border-dashed border-2 border-black py-16 px-8 rounded-lg min-h-[150px] flex justify-center items-center ${outline ? " drop-zone-container-outlined" : ""}`}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <div className="text-xl">
                        You got this :)
                    </div>
                    :
                    <div>
                        {loading ?
                            <CircularProgress size={40}/>
                            :
                            <div className="flex flex-col items-center justify-center">
                                <div className="drop-zone-title">
                                    Drag 'n' drop files here
                                </div>
                                <div className="mt-5">
                                    -- or --
                                </div>
                                <div className="mt-5">
                                    <Button variant="contained" color="primary">click to select files</Button>
                                </div>
                            </div>
                        }
                    </div>
            }
            <NICADialog
                title="File Not Supported"
                open={showNotSupportedDialog}
                onClose={() => { setShowNotSupportedDialog(false) }}
                actions={[{label: 'OK', onClick: () => { setShowNotSupportedDialog(false) }}]}>
                <div>
                    Sorry, the file type chosen is not supported
                </div>
            </NICADialog>
        </div>
    )
};

export default DropZone;

