import attributeIds from '@devsontap/nica-api/core/utils/attributeIds';

import {useShallowEqualSelector} from "../index";

// /**
//  * Returns the data necessary to render the attribute list
//  */
// export const useAttributeListSelector = () => {
//     return useShallowEqualSelector(({ attributes }) => {
//         const attributeList = attributes.attributeIds ? attributes.attributeIds.map(id => attributes.attributeMap[id]) : null;
//         return {
//             ...(_.pick(attributes, ["loading", "attributeIds", "error"])),
//             attributeList
//         };
//     });
// };
//
// export const useAttributeInflatorSelector = () => {
//     return useShallowEqualSelector(({ attributes }) => {
//         return (attributes.attributeIds && attributes.attributeIds.map(id => attributes.attributeMap[id])) || [];
//     });
// }
//
// /**
//  * Return a single Attribute record
//  * @param attributeId    id of the attribute to return
//  */
// export const useAttributeSelector = (attributeId) => {
//     return useShallowEqualSelector(({ attributes }) => {
//         return attributes.attributeMap[attributeId] ? attributes.attributeMap[attributeId] : null;
//     });
// };

export const useMedicationsAvailableSelector = () => {
    return useShallowEqualSelector(({ attributes: { attributeMap } }) => {
        if (attributeMap[attributeIds.medicationsAvailable]) {
            const { groups } = attributeMap[attributeIds.medicationsAvailable];
            return Object
                .keys(groups)
                .map(groupId => {
                    const options = groups[groupId].options;
                    // return Object.keys(options).map(optionId => ({ id: optionId, name: options[optionId].name }))
                    return Object.keys(options).map(optionId => ({key: optionId, groupKey: groupId, option: groups[groupId].options[optionId]}))
                })
                .reduce((result, groupOptions) => [...result, ...groupOptions], [])
                .sort((item1, item2) => item1.option.name.localeCompare(item2.option.name))
        }
        return null;
    });
};

export const useSpecialtiesSelector = () => {
    return useShallowEqualSelector(({ attributes: { attributeMap } }) => {
        if (attributeMap[attributeIds.practiceSpecialty]) {
            const { options } = attributeMap[attributeIds.practiceSpecialty];
            return Object
                .keys(options)
                // .map(optionId => ({ id: optionId, name: options[optionId].name }))
                .map(optionKey => ({key: optionKey, option: options[optionKey]}))
                .sort((item1, item2) => item1.option.name.localeCompare(item2.option.name))
        }
        return null;
    });
};

export const useAttributeIconSelector = (attributeId) => {
    return useShallowEqualSelector(({ attributes: { attributeMap } }) => {
        return attributeMap && attributeMap[attributeId] ? attributeMap[attributeId].icon : "";
    });
};

export const useAttributeMapSelector = () => {
    return useShallowEqualSelector(({ attributes: { attributeMap } }) => {
        return attributeMap;
    });
};

/**
 * Return a single Location record
 * @param attributeId    id of the attribute to return
 */
export const useAttributeSelector = (attributeId) => {
    return useShallowEqualSelector(({ attributes }) => {
        return attributes.attributeMap[attributeId] ? attributes.attributeMap[attributeId] : null;
    });
};