import {createTheme} from "@mui/material";

/**
 * This is the Material-UI theme config for the Application
 */
const theme = createTheme({
    palette: {
        primary: {
            main: '#1c5c7f',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#53c9e8',
            contrastText: '#ffffff',
        },
        // error: will use the default color
    },
    typography: {
        fontFamily: [
            'Lato, sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        useNextVariants: true
    },
    components: {
    // overrides: {
        // MuiInputAdornment: {
        //     root: {
        //         marginRight: "14px"
        //     }
        // },
        // MuiInputBase: {
        //     adornedEnd: {
        //         paddingRight: "0px !important"
        //     }
        // },
        MuiInput: {
            styleOverrides: {
                root: {
                    color: "#18496c",
                    fontSize: "18px",
                    fontWeight: 200,
                },
                underline: {
                    '&:before': { //underline color when textfield is inactive
                        borderBottom: '2px solid #D5D5D5',
                    },
                    '&:hover:not($disabled):before': { //underline color when hovered
                        borderBottom: '2px solid #A4A4A4',
                    },
                    '&:after': {
                        borderBottom: '2px solid #1c5c7f'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {root: {
                    color: '#A4A4A4',
                    fontSize: "18px",
                    fontWeight: 300
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    color: "#53c9e8",
                    fontFamily: "Oswald",
                    fontSize: "16px",
                    fontWeight: 400,
                    minWidth: "80px"
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                button: {
                    paddingTop: "2px",
                    paddingBottom: "2px"
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginLeft: "5px",
                    minWidth: "50px"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    // zIndex: 1302
                },
                paperWidthSm: {
                    margin: "25px",
                    width: "100%"
                }
            }
        }
    }
});

export default theme;
