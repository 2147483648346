import React from 'react';
import {NavLink, Route, Routes} from 'react-router-dom';
import {List, ListItem, Button, ListItemIcon} from '@mui/material';
import {LocationOn, Abc, DeleteOutlined} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import cx from "classnames";

import {signOut} from "../../redux/login";

import logo from '../../img/nica-logo.svg';

const NAV_ITEMS = [
    { label: 'NICA Location Import', path: '/nica', Icon: LocationOn },
    { label: 'ABC Import', path: '/abc', Icon: Abc },
    { label: 'Bulk Soft Delete', path: '/delete', Icon: DeleteOutlined },
];

const Nav = () => {
    const dispatch = useDispatch();

    return (
        <div className="w-[300px] h-screen bg-white text-left border-0 border-r-[rgba(0, 0, 0, 0.12)] border-solid">
            <div className="bg-blue p-2">
                <div className="flex justify-end">
                    <Button variant="text" onClick={() => dispatch(signOut())}>
                        Log Out
                    </Button>
                </div>
                <div>
                    <img src={logo} className="ml-5" alt="nica logo" />
                </div>
                <div className="text-lg font-medium uppercase text-white mt-5 mb-3 ml-5 font-oswald">
                    NICA Imports
                </div>
            </div>
            <div className="admin-nav-card-items">
                <List component="nav">
                    {NAV_ITEMS.map(({ label, path, Icon }, index) => {
                        return (
                            <NavLink key={`nav-item-${label}`} to={path} className={({ isActive }) => cx( "text-base font-bold no-underline", {
                                "text-blue": isActive,
                                "text-[#A0A0A0]": !isActive
                            })}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <Routes>
                                            <Route path={path} element={<Icon htmlColor="#1c5c7f" />} />
                                            <Route path="*" element={<Icon htmlColor="#A0A0A0" />} />
                                        </Routes>
                                    </ListItemIcon>
                                    <div className="h-12 flex items-center">
                                        {label}
                                    </div>
                                </ListItem>
                            </NavLink>
                        );
                    })}
                </List>
            </div>
        </div>
    )

};

export default Nav;
