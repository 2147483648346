import { createSelector } from "@reduxjs/toolkit";

export const importsMapSelector = state => state.imports.importsMap;
export const importSelector = (importId) => state => state.imports.importsMap[importId];
export const importUnsubscribeSelector = state => state.imports.unsubscribe;
export const importHitsSelector = state => state.imports.hits;
export const importMissesSelector = state => state.imports.misses;

export const importsSelector = createSelector(importsMapSelector,
    (importsMap) => Object.values(importsMap)
);