import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import {enqueueSnackbar} from "notistack";

/**
 * Log a warning and show a toast!
 */
export const snackbarErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {
            const message = 'data' in action.error
                ? (action.error.data as { message: string }).message
                : action.payload.message
            enqueueSnackbar(message, { variant: 'error' });
            // toast.warn({
            //     title: 'Async error!',
            //     message:
            //         'data' in action.error
            //             ? (action.error.data as { message: string }).message
            //             : action.error.message,
            // })
        }

        return next(action)
    };
