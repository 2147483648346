import React, {useState, useEffect} from 'react';
import {CircularProgress, Table, TableContainer, TableHead, TableRow, Card, Button, TableCell} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

import {useBulkDeleteLocationsMutation, useGetBulkSoftDeleteRecordsQuery} from "../../redux/api";
import {BulkSoftDeleteRow} from "../../types";
import NICADialog from "../_common/dialog";

const BulkSoftDelete: React.FC = () => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [completed, setCompleted] = useState(false);
    const {data, isLoading} = useGetBulkSoftDeleteRecordsQuery(null);
    const [bulkDeleteLocations, {isLoading: isDeleting, data: result}] = useBulkDeleteLocationsMutation();

    useEffect(() => {
        if (result) {
            enqueueSnackbar(`Deleted ${data.length} Location(s)`, {variant: 'success'});
            setCompleted(true);
        }
    }, [result, data]);

    const onBulkDeleteClick = () => {
        setConfirmOpen(false);
        bulkDeleteLocations(data);
    };

    return (
        <div className="p-10">
            {isLoading ?
                <CircularProgress size={45} /> :
                <div>
                    {!data?.length ?
                        <div>
                            No records to display.
                        </div> :
                        <div>
                            {completed ?
                                <div className="text-center">
                                    <div className="text-lg font-bold font-oswald flex items-center justify-center text-blue">
                                        <CheckCircle className="mr-2" color="success" /> Successfully deleted {data.length} Location(s).
                                    </div>
                                    <div className="flex justify-center mt-6">
                                        <Button variant="contained" color="primary" onClick={() => setCompleted(false)}>
                                            Done
                                        </Button>
                                    </div>
                                </div> :
                                <div>
                                    <Card>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <strong>Location ID</strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>Name</strong>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {data.map((row: BulkSoftDeleteRow) => (
                                                    <TableRow key={row.locationId}>
                                                        <TableCell>
                                                            {row.locationId}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.name}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </Table>
                                        </TableContainer>
                                    </Card>
                                    <div className="flex mt-8 justify-center">
                                        {isDeleting ?
                                            <CircularProgress size={45}/> :
                                            <Button variant="contained" color="primary"
                                                    onClick={() => setConfirmOpen(true)}>
                                                Bulk Soft Delete {data.length} Location(s)
                                            </Button>
                                        }
                                    </div>
                                </div>
                            }
                            <NICADialog
                                open={confirmOpen}
                                title="Confirm Soft Delete"
                                onClose={() => setConfirmOpen(false)}
                                actions={[
                                    { label: 'Cancel', onClick: () => setConfirmOpen(false) },
                                    { label: 'Delete', color: 'error', onClick: onBulkDeleteClick },
                                ]}>
                                You are about to Soft Delete {data?.length} Location(s).
                            </NICADialog>
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default BulkSoftDelete;
