import React, { useState } from 'react';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import cx from "classnames";
import TextField from "@mui/material/TextField";

import NICADialog from "../../../_common/dialog";
import {SOURCE_TYPE_IMPORT, SOURCE_TYPE_NICA, SOURCE_TYPE_OTHER} from "./";

const AcceptHitDialog = ({ acceptHit, onClose, onAcceptHit }) => {
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zip, setZip] = useState(null);

    const closeAcceptHitDialog = () => {
        setName(null);
        setAddress(null);
        setCity(null);
        setState(null);
        setZip(null);
        onClose();
    };

    const selectAllImport = () => {
        setName(acceptHit[1]);
        setAddress(acceptHit[3]);
        setCity(acceptHit[5]);
        setState(acceptHit[7]);
        setZip(acceptHit[9]);
    };

    const selectAllNICA = () => {
        setName(acceptHit[2]);
        setAddress(acceptHit[4]);
        setCity(acceptHit[6]);
        setState(acceptHit[8]);
        setZip(acceptHit[10]);
    };

    const selectAllOther = () => {
        setName('');
        setAddress('');
        setCity('');
        setState('');
        setZip('');
    };

    return (
        <NICADialog
            onClose={closeAcceptHitDialog}
            open={acceptHit[0] !== undefined}
            title="Reconcile Hit"
            maxWidth="lg">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                                <div className="flex">
                                    <div className="flex items-center font-bold">
                                        {SOURCE_TYPE_IMPORT}
                                    </div>
                                    <div className="ml-6">
                                        <Button variant="text" onClick={selectAllImport}>
                                            Select All
                                        </Button>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className="flex">
                                    <div className="flex items-center font-bold">
                                        {SOURCE_TYPE_NICA}
                                    </div>
                                    <div className="ml-6">
                                        <Button variant="text" onClick={selectAllNICA}>
                                            Select All
                                        </Button>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className="flex">
                                    <div className="flex items-center font-bold">
                                        {SOURCE_TYPE_OTHER}
                                    </div>
                                    <div className="ml-6">
                                        <Button variant="text" onClick={selectAllOther}>
                                            Select All
                                        </Button>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    Name
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": name === acceptHit[1]
                                })} onClick={() => setName(acceptHit[1])}>
                                    {acceptHit[1]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": name === acceptHit[2]
                                })} onClick={() => setName(acceptHit[2])}>
                                    {acceptHit[2]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": name !== acceptHit[1] && name !== acceptHit[2]
                                })}>
                                    <TextField
                                        name="name"
                                        placeholder="Bob's Infusion Palace"
                                        label="Location Name"
                                        onChange={e => setName(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    Address
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": address === acceptHit[3]
                                })} onClick={() => setAddress(acceptHit[3])}>
                                    {acceptHit[3]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": address === acceptHit[4]
                                })} onClick={() => setAddress(acceptHit[4])}>
                                    {acceptHit[4]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": address !== acceptHit[3] && address !== acceptHit[4]
                                })}>
                                    <TextField
                                        name="address"
                                        placeholder="123 Street Place Drive"
                                        label="Location Address"
                                        onChange={e => setAddress(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    City
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": city === acceptHit[5]
                                })} onClick={() => setCity(acceptHit[5])}>
                                    {acceptHit[5]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": city === acceptHit[6]
                                })} onClick={() => setCity(acceptHit[6])}>
                                    {acceptHit[6]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": city !== acceptHit[5] && city !== acceptHit[6]
                                })}>
                                    <TextField
                                        name="city"
                                        placeholder="Austin"
                                        label="Location City"
                                        onChange={e => setCity(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    State
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": state === acceptHit[7]
                                })} onClick={() => setState(acceptHit[7])}>
                                    {acceptHit[7]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": state === acceptHit[8]
                                })} onClick={() => setState(acceptHit[8])}>
                                    {acceptHit[8]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": state !== acceptHit[7] && state !== acceptHit[8]
                                })}>
                                    <TextField
                                        name="state"
                                        placeholder="TX"
                                        label="State"
                                        onChange={e => setState(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left">
                                <div className="font-bold">
                                    Zip
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": zip === acceptHit[9]
                                })} onClick={() => setZip(acceptHit[9])}>
                                    {acceptHit[9]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("cursor-pointer p-4", {
                                    "bg-dark-blue text-white": zip === acceptHit[10]
                                })} onClick={() => setZip(acceptHit[10])}>
                                    {acceptHit[10]}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={cx("w-[200px] cursor-pointer p-3", {
                                    "ring-2 ring-dark-blue rounded text-white": zip !== acceptHit[9] && zip !== acceptHit[10]
                                })}>
                                    <TextField
                                        name="zip"
                                        placeholder="12345"
                                        label="Location Zip"
                                        onChange={e => setZip(e.target.value)}
                                        fullWidth />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-end mt-6">
                <div className="mr-4">
                    <Button variant="text" onClick={closeAcceptHitDialog} color="default">
                        Cancel
                    </Button>
                </div>
                <Button variant="text" onClick={() => onAcceptHit(name, address, city, state, zip)} disabled={!name || !address || !city || !state || !zip}>
                    Save
                </Button>
            </div>
        </NICADialog>
    )
};

export default AcceptHitDialog;
