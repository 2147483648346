import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { format } from "date-fns";
import Fab from "@mui/material/Fab";
import Add from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import useImportsByTypeFilter from "../../hooks/useImportsByTypeFilter";
import { fetchImports, clear } from "../../redux/imports";
import FullscreenDialog from "../_common/dialog/fullScreen";
import ImportDetails from './details';
import { loadAttributes } from "../../redux/attributes/actions";

const ImportsList = () => {
    const { importType } = useParams();
    const imports = useImportsByTypeFilter(importType);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const { importId } = useParams();

    // TODO: Move this
    useEffect(() => {
        dispatch(loadAttributes(enqueueSnackbar))
    }, [dispatch, enqueueSnackbar]);

    useEffect(() => {
        dispatch(fetchImports(importType, enqueueSnackbar));
    }, [importType, dispatch, enqueueSnackbar]);

    const onBack = () => {
        dispatch(clear());
        navigate(-1);
    };

    return (
        <div className="relative">
            <div className="flex justify-end pr-8 -mt-8 fixed z-[1101] right-8">
                <Fab size="md" color="secondary" onClick={() => navigate(`${location.pathname}/add`)}>
                    <Add htmlColor="#ffffff" />
                </Fab>
            </div>
            <div className="p-5 text-left">
                <div className="my-4 font-bold">
                    IN PROGRESS
                </div>
                <div className="no-underline">
                    {imports?.map((importRecord, index) => (
                        <Link key={`import-${importRecord.id}`} to={importRecord.id} className="no-underline">
                            <Card className="cursor-pointer mb-6">
                                <CardContent className="flex">
                                    <div className="flex-1">
                                        <div className="text-xl">
                                            {format(importRecord.createdAt, "MM/dd/yyyy")}
                                        </div>
                                        <div>
                                            {importRecord.numHits} hits | {importRecord.numMisses} misses
                                        </div>
                                    </div>
                                    <div className="flex items-center text-xl font-bold mr-6">
                                        &gt;
                                    </div>
                                </CardContent>
                            </Card>
                        </Link>
                    ))}
                </div>
            </div>
            <FullscreenDialog onClose={onBack} open={Boolean(importId)}>
                <ImportDetails />
            </FullscreenDialog>
        </div>
    );
};

export default ImportsList;
