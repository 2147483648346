import React, { useMemo } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {useMedicationsAvailableSelector} from "../../../redux/attributes/selectors";

const MedicationSelect = ({ selectedMedications, setSelectedMedications, ...otherProps }) => {
    const medications = useMedicationsAvailableSelector();

    const medicationsAvailableOptions = useMemo(() => {
        if (medications) {
            return medications
                .map((medication, index) => (
                <MenuItem
                    key={"medication-" + index}
                    value={JSON.stringify({key: medication.key, groupKey: medication.groupKey, label: medication.option.name, index})}>
                    <span className={!!selectedMedications?.find(med => med.key === medication.key) ? "locator-form-filter-selected" : ""}>
                        {medication.option.name}
                    </span>
                </MenuItem>
            ));
        }
        return null;
    }, [medications, selectedMedications]);

    return (
        <Select
            name="medication"
            style={{fontSize: "15px", fontFamily: "Lato", color: "#1c5c7f", lineHeight: "normal"}}
            multiple={true}
            fullWidth={true}
            value={selectedMedications?.map(med => JSON.stringify(med)) || []}
            displayEmpty={true}
            renderValue={value => {
                const names = value?.map(val => JSON.parse(val).label).join(", ");
                return value?.length ? names : "No Medications";
            }}
            onChange={event => {
                const values = event.target.value.map(value => JSON.parse(value));
                setSelectedMedications(values);
                // TODO: Tracking
                // if (this.props.filter.medicationsAvailable.length || (values && values.length)) {
                //     Tracker.trackEvent(CATEGORY, "medication filter change", values.join(", "), "");
                // }
                //
                // api.trackMedicationFilter(null, {
                //     ...this.props.filter,
                //     medicationsAvailable: values,
                // }, this.state.medicationsAvailable);
            }}
            {...otherProps}>
            {medicationsAvailableOptions}
        </Select>
    )
};

export default MedicationSelect;
