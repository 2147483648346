import { useMemo } from 'react';
import {useSelector} from "react-redux";

import {importsSelector} from "../redux/imports/selectors";

function useImportsByTypeFilter(importType) {
    const imports = useSelector(importsSelector);
    return useMemo(() => {
        return imports.filter(importRecord => importRecord.importType === importType.toUpperCase())
    }, [imports, importType]);
}

export default useImportsByTypeFilter;