import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import LoginForm from "./loginForm";

import logo from '../../img/nica-logo.svg';

const Login = () => {
    return (
        <div className="h-screen flex flex-col relative">
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="ml-4 text-left flex w100">
                        <img src={logo} alt="nica logo" className="h-9 w-auto" />
                    </div>
                </Toolbar>
            </AppBar>
            <div className="flex flex-col justify-center items-center flex-1">
                <div className="bg-login bg-no-repeat bg-cover bg-center blur-md absolute inset-0 z-10" />
                <Card className="w-[600px] -mt-[160px] z-20">
                    <CardContent>
                        <LoginForm />
                    </CardContent>
                </Card>
            </div>
        </div>
    )
};

export default Login;
